import React from "react"

import { useShopify } from "../../../../hooks/useShopify"

export const withCustomerPaymentsItem = Component => ({ name = `CustomerPaymentsItem`, item }) => {
  const { formatMoney } = useShopify()

  const amount = item?.discountedTotalPrice && formatMoney(item.discountedTotalPrice.amount)

  Component.displayName = name
  return <Component amount={amount} item={item} />
}
